import { ApolloClient, gql, useApolloClient } from '@apollo/client';
import { Button, Modal, Paper, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { inspect } from 'util';
import { v4 } from 'uuid';
import JobConfiguration from '../JobConfiguration';
import { useAuth, User } from 'src/hooks/useAuth';
import AssetSelection from 'src/components/AssetSelection';
import PostProcessingList from 'src/components/PostProcessingList';
import FileUploadsList from 'src/components/FileUploadsList';
import {
  formatBurnTimes,
  getFlatDate,
  jobFieldKeys,
  assetFieldKeys,
  postProcessingKeys,
  JobFieldsType,
  MetadataFileFields,
  ProcessMessageType,
  postProcessingAssetMap,
  JobFieldData,
  PostProcessingAssets,
  PostProcessingSelection,
  defaultDate,
} from 'src/util';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-dropzone-uploader/dist/styles.css';
import style from './style.module.css';

type RedirectType = {
  redirect: boolean;
  page: string;
};

const loadInitialFiles = async (
  user: User,
  apollo: ApolloClient<object>,
  setJobFields: React.Dispatch<React.SetStateAction<JobFieldData>>,
  setPostProcessingAssets: React.Dispatch<React.SetStateAction<PostProcessingAssets>>,
  setPostProcessing: React.Dispatch<React.SetStateAction<PostProcessingSelection>>,
  setJobTitle: React.Dispatch<React.SetStateAction<string>>,
  setSimulationDate: React.Dispatch<React.SetStateAction<string>>,
  setwfb: React.Dispatch<React.SetStateAction<boolean>>,
  copyId?: string,
) => {
  try {
    const result = await apollo.query({
      query: gql`
        query {
          getFileDefaults {
            body
            statusCode
          }
          ${
            copyId
              ? `getJobMetadataFile(jobId: "${copyId}") {
                body
                statusCode
              }`
              : ''
          }
        }
      `,
    });

    const assetKeys = { ...jobFieldKeys, ...assetFieldKeys };

    const content = result?.data?.getFileDefaults
      ? (JSON.parse(result.data?.getFileDefaults?.body) as string[]).filter((fileDefault) =>
          Object.keys(assetKeys).includes(fileDefault.split('/')[0]),
        )
      : undefined;

    const copyMetadata = result?.data?.getJobMetadataFile
      ? (JSON.parse(result.data?.getJobMetadataFile?.body) as {
          [key in keyof typeof jobFieldKeys]: MetadataFileFields[];
        } & { [key in keyof typeof postProcessingKeys]: boolean } & {
          [key in keyof typeof assetFieldKeys]: JobFieldsType;
        } & { noFireHistory: boolean } & { title: string })
      : undefined;

    const output = content?.reduce(
      (acc, key) => {
        const data = key.split('/');

        if (
          acc[data[0] as keyof typeof jobFieldKeys].some(
            (item) =>
              item.bucket === process.env.REACT_APP_FILE_DEFAULTS_BUCKET && item.key === key,
          )
        ) {
          return acc;
        }

        return {
          ...acc,
          [data[0]]: [
            ...acc[data[0] as keyof typeof jobFieldKeys],
            {
              ...(data[0] === 'fireHistory'
                ? { burnTimes: [formatBurnTimes(getFlatDate())], newBurnTime: getFlatDate() }
                : {}),
              bucket: process.env.REACT_APP_FILE_DEFAULTS_BUCKET,
              // If we're not loading an existing copy, automatically tick the first loaded default file
              checked:
                copyMetadata || !(data[0] in jobFieldKeys)
                  ? false
                  : !acc[data[0] as keyof typeof jobFieldKeys].length,
              key,
              title: data[1],
            },
          ],
        };
      },
      Object.keys(jobFieldKeys).reduce(
        (acc, key) => ({
          ...acc,
          [key]: copyMetadata
            ? copyMetadata[key as keyof typeof jobFieldKeys]
                .filter(
                  (item) =>
                    key !== 'fireHistory' || (key === 'fireHistory' && item.key !== undefined),
                )
                .map((item) => ({
                  ...(key === 'fireHistory'
                    ? { burnTimes: item.burnTimes, newBurnTime: getFlatDate() }
                    : {}),
                  bucket: item.bucket,
                  checked: true,
                  key: item.key,
                  ...(key === 'fireHistory' && item.key === undefined
                    ? { title: 'No Fire History' }
                    : { title: item.key.split('/')[1] }),
                }))
            : [],
        }),
        {
          assetBasicHouseLoss: copyMetadata
            ? copyMetadata.assetBasicHouseLoss
              ? [
                  {
                    checked: true,
                    title: copyMetadata.assetBasicHouseLoss.title,
                    bucket: copyMetadata.assetBasicHouseLoss.bucket,
                    key: copyMetadata.assetBasicHouseLoss.key,
                  },
                ]
              : []
            : ([] as JobFieldsType[]),
          assetCriticalInfrastructure: copyMetadata
            ? copyMetadata.assetCriticalInfrastructure
              ? [
                  {
                    checked: true,
                    title: copyMetadata.assetCriticalInfrastructure.title,
                    bucket: copyMetadata.assetCriticalInfrastructure.bucket,
                    key: copyMetadata.assetCriticalInfrastructure.key,
                  },
                ]
              : []
            : ([] as JobFieldsType[]),
        } as {
          [key in keyof typeof jobFieldKeys]: JobFieldsType[];
        } & {
          [key in keyof typeof assetFieldKeys]: JobFieldsType[];
        },
      ),
    );

    if (output) {
      Object.keys(jobFieldKeys).forEach((key) =>
        setJobFields((prevData) => ({
          ...prevData,
          ...{ [key]: output[key as keyof typeof jobFieldKeys] as JobFieldsType[] },
        })),
      );
      Object.keys(assetFieldKeys).forEach((key) =>
        setPostProcessingAssets((prevData) => ({
          ...prevData,
          ...{ [key]: output[key as keyof typeof assetFieldKeys] as JobFieldsType[] },
        })),
      );
    }

    if (output && copyMetadata) {
      const postProcessingData = Object.fromEntries(
        Object.keys(postProcessingKeys)
          .filter((key) => key in copyMetadata)
          .map((key) => [key, copyMetadata[key as keyof typeof postProcessingKeys]]),
      );

      setPostProcessing((prevData) => ({
        ...prevData,
        ...postProcessingData,
      }));
      setJobTitle(`Copy of ${copyMetadata.title}`);
      setSimulationDate(copyMetadata.fireHistory[0].burnTimes![0].date);
      setwfb(copyMetadata.noFireHistory);
    }
  } catch (error) {
    console.error('Error fetching default files:', error);
  }
};

const checkSubmitIsValid = (data: {
  postProcessing: PostProcessingSelection;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setProcessMessage: React.Dispatch<React.SetStateAction<ProcessMessageType>>;
  title: string;
}) => {
  if (!data.title) {
    const errorMessage = 'Error: missing Title';
    console.error(errorMessage);
    data.setErrorMessage(errorMessage);
    return false;
  }

  if (!Object.values(data.postProcessing).includes(true)) {
    const errorMessage = 'Error: please select a Post Processing Option';
    console.error(errorMessage);
    data.setErrorMessage(errorMessage);
    return false;
  }

  data.setProcessMessage('Processing ...');
  return true;
};

const handleSubmit = async (
  user: User,
  apollo: ApolloClient<object>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  setProcessMessage: React.Dispatch<React.SetStateAction<ProcessMessageType>>,
  setRedirect: React.Dispatch<React.SetStateAction<RedirectType>>,
  jobFieldData: JobFieldData,
  postProcessingAssets: PostProcessingAssets,
  postProcessing: PostProcessingSelection,
  uploadId: string,
  title: string,
  matchFuel: boolean,
  keepKMZEnabled: boolean,
  noFireHistory: boolean,
  simulationDate: string,
) => {
  if (!checkSubmitIsValid({ postProcessing, setErrorMessage, setProcessMessage, title })) {
    return;
  }

  const data = {
    id: uploadId,
    creator: user.username,
    title: title,
    noFireHistory: noFireHistory,
    matchFuel: matchFuel,
    keepKMZEnabled,
    ...Object.keys(assetFieldKeys)
      .filter((key) =>
        postProcessingAssets[key as keyof typeof assetFieldKeys].find((asset) => asset.checked),
      )
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: postProcessingAssets[key as keyof typeof assetFieldKeys]
            .filter(({ checked }) => checked)
            .map((item) => ({
              title: item.title,
              bucket: item.bucket,
              key: item.key,
            }))[0],
        }),
        {},
      ),
    ...Object.keys(jobFieldKeys).reduce(
      (acc, key) => ({
        ...acc,
        ...(key === 'fireHistory' && noFireHistory
          ? {
              [key]: [
                {
                  title: 'No Fire History',
                  burnTimes: [{ date: simulationDate, endTime: '23:00', startTime: '11:00' }],
                } as any,
              ],
            }
          : {
              [key]: jobFieldData[key as keyof typeof jobFieldKeys]
                .filter(({ checked }) => checked)
                .map((item) => ({
                  title: item.title,
                  bucket: item.bucket,
                  key: item.key,
                  ...(key === 'fireHistory' ? { burnTimes: item.burnTimes } : {}),
                })),
            }),
      }),
      {},
    ),
    ...Object.keys(postProcessingKeys).reduce(
      (acc, key) => ({
        ...acc,
        [key]: postProcessing[key as keyof typeof postProcessingKeys] ?? false,
      }),
      {},
    ),
  };

  // We take our data object and unfold it into parameters for graphql, but the nicest native unfold
  // means we need to replace quotes and remove the opening and closing brackets
  return apollo
    .mutate({
      mutation: gql`
      mutation {
        createJob(${inspect(data, { compact: true, depth: null })
          .replace(/'/g, '"')
          .replace(/^{|}$/g, '')}) {
            body
            statusCode
          }
      }`,
    })
    .then((response) => {
      if (response.data.createJob.statusCode === '200') {
        console.trace('A new job has been submitted:', data, response.data.createJob.body);
        setProcessMessage('Job sent successfully!');
        setRedirect({ redirect: true, page: `/job/id/${data.id}` });
      } else {
        console.trace('Job failed to be submitted:', data, response.data.createJob.body);
        setErrorMessage(response.data.createJob.body);
      }
    })
    .catch((error) => {
      if (error.message === 'Execution timed out.') {
        console.error('There was an error submitting the job:', data, error);
        setRedirect({ redirect: true, page: `/job/id/${data.id}` });
      } else {
        console.error('There was an error submitting the job:', data, error);
        setErrorMessage(error.message);
      }
    });
};

const defaultPostProcessing = Object.keys(postProcessingKeys).reduce(
  (acc, fieldKey) => ({ ...acc, [fieldKey]: false }),
  {},
) as PostProcessingSelection;
const defaultPostProcessingAssets = Object.keys(assetFieldKeys).reduce(
  (acc, fieldKey) => ({ ...acc, [fieldKey]: [] }),
  {},
) as PostProcessingAssets;
const defaultJobFieldData = Object.keys(jobFieldKeys).reduce(
  (acc, fieldKey) => ({ ...acc, [fieldKey]: [] }),
  {},
) as JobFieldData;

export function UploadForm() {
  const user = useAuth();
  const apollo = useApolloClient();
  const { copyId } = useParams<{ copyId?: string }>();
  const [wfb, setwfb] = useState(false);
  const [matchFuel, setMatchFuel] = useState(false);
  const [keepKMZEnabled, setKeepKMZEnabled] = useState(false);
  const [simulationDate, setSimulationDate] = useState(defaultDate());
  const [jobFieldData, setJobFields] = useState<JobFieldData>(defaultJobFieldData);
  const [postProcessingAssets, setPostProcessingAssets] = useState<PostProcessingAssets>(
    defaultPostProcessingAssets,
  );
  const [postProcessing, setPostProcessing] =
    useState<PostProcessingSelection>(defaultPostProcessing);
  const [errorMessage, setErrorMessage] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [processMessage, setProcessMessage] = useState<ProcessMessageType>('Process Files');
  const [redirect, setRedirect] = useState<RedirectType>({ redirect: false, page: '' });
  // We strip the separators as this gets passed to a number of systems downstream that can't handle dashes or underscores
  const [uploadId, setUploadId] = useState(v4().replace(/-/g, ''));
  const [showKMZModal, setShowKMZModal] = useState(false);
  const navigate = useNavigate();

  // On the first page load, or if we choose to copy a new job, refresh the files loaded on the page
  useEffect(() => {
    (async () =>
      await loadInitialFiles(
        user,
        apollo,
        setJobFields,
        setPostProcessingAssets,
        setPostProcessing,
        setJobTitle,
        setSimulationDate,
        setwfb,
        copyId,
      ))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyId]);

  const closeErrorModal = () => {
    setErrorMessage('');
    setProcessMessage('Process Files');
  };

  const closeKMZModal = () => {
    setShowKMZModal(false);
    setProcessMessage('Process Files');
  };

  return redirect.redirect ? (
    <Navigate to={redirect.page} />
  ) : (
    <div className={style.uploadFormContainer}>
      <Modal open={!!errorMessage} onClose={closeErrorModal}>
        <div className={style.modalContent}>
          <h2>Error:</h2>
          <p>{errorMessage}</p>
          <Button
            className={style.modalButton}
            color="secondary"
            onClick={closeErrorModal}
            variant="contained"
          >
            Close
          </Button>
        </div>
      </Modal>

      <Modal open={showKMZModal} onClose={closeKMZModal}>
        <div className={style.modalContent}>
          <h2>KMZ File Warning:</h2>
          <p>
            KMZ files are large and expensive to store. If you need KMZ files for this run, click
            'Yes' to proceed. Files will be available for 7 days before moving to deep storage, and
            deleted after 30 days. Click 'No' to return to the main screen and change your
            selection.
          </p>

          <div className={style.modalButtonContainer}>
            <Button color="primary" onClick={closeKMZModal} variant="outlined">
              No
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                handleSubmit(
                  user,
                  apollo,
                  setErrorMessage,
                  setProcessMessage,
                  setRedirect,
                  jobFieldData,
                  postProcessingAssets,
                  postProcessing,
                  uploadId,
                  jobTitle,
                  matchFuel,
                  keepKMZEnabled,
                  wfb,
                  simulationDate,
                );
                setShowKMZModal(false);
              }}
              variant="contained"
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>

      <h1>Create new Job</h1>

      <div className={style.configControls}>
        <TextField
          className={style.customTitle}
          label="Job title"
          onChange={(event) => setJobTitle(event.target.value)}
          value={jobTitle}
          variant="outlined"
        />
        <JobConfiguration
          wfb={wfb}
          setwfb={setwfb}
          matchFuel={matchFuel}
          setMatchFuel={setMatchFuel}
          keepKMZEnabled={keepKMZEnabled}
          setKeepKMZEnabled={setKeepKMZEnabled}
          simulationDate={simulationDate}
        />
        <div className={style.groupId}>
          Group ID: <strong>{uploadId}</strong>
        </div>
      </div>

      <Paper className={style.uploadTabs}>
        <h2>Phoenix Configuration</h2>
        <FileUploadsList
          checkHandler={(key, data, jobIndex) => {
            if (jobIndex === undefined) {
              jobFieldData[key] = [...jobFieldData[key], data];
              setJobFields((prevData) => ({ ...prevData, ...jobFieldData }));
            } else {
              jobFieldData[key] = jobFieldData[key].map((item, index) =>
                index === jobIndex ? data : item,
              );
              setJobFields((prevData) => ({ ...prevData, ...jobFieldData }));
            }
          }}
          data={jobFieldData}
          uploadId={uploadId}
          wfb={wfb}
        />

        <h2>Post Processing Options</h2>
        <PostProcessingList
          checkHandler={(key, checked) => {
            setPostProcessing((prevData) => ({
              ...prevData,
              ...{ [key]: checked },
            }));
            if (postProcessingAssetMap.hasOwnProperty(key) && !checked) {
              postProcessingAssets[
                postProcessingAssetMap[key as keyof typeof postProcessingAssetMap]
              ].map((asset) => (asset.checked = checked));
              setPostProcessingAssets((prevData) => ({
                ...prevData,
                ...postProcessingAssets,
              }));
            }
          }}
          data={postProcessing}
        />
        <AssetSelection
          postProcessingAssets={postProcessingAssets}
          postProcessing={postProcessing}
          checkHandler={(key, asset) => {
            if (asset) {
              postProcessingAssets[key] = postProcessingAssets[key].map((obj) => {
                obj.checked = false;
                if (obj.key === asset.key) {
                  obj.checked = true;
                }

                return obj;
              });
              setPostProcessingAssets((prevData) => ({
                ...prevData,
                ...postProcessingAssets,
              }));
            }
          }}
        />
      </Paper>

      <div className={style.formControls}>
        <Button
          color="secondary"
          onClick={() => {
            navigate(`/job/new`);
            setUploadId(v4().replace(/-/g, ''));
            setProcessMessage('Process Files');
            setJobTitle('');
            setwfb(false);
            setProcessMessage('Process Files');
            setPostProcessing(defaultPostProcessing);
          }}
          variant="contained"
        >
          Clear current job
        </Button>
        <Button
          color="primary"
          // If there are files that have been uploaded but aren't complete, disable the process button
          disabled={
            processMessage !== 'Process Files' ||
            Object.keys(jobFieldKeys).some((key) =>
              jobFieldData[key as keyof typeof jobFieldKeys].some(
                (item) => item.file?.xhr && item.file.xhr.readyState !== 4,
              ),
            )
          }
          onClick={() =>
            keepKMZEnabled
              ? checkSubmitIsValid({
                  postProcessing,
                  setErrorMessage,
                  setProcessMessage,
                  title: jobTitle,
                }) && setShowKMZModal(true)
              : handleSubmit(
                  user,
                  apollo,
                  setErrorMessage,
                  setProcessMessage,
                  setRedirect,
                  jobFieldData,
                  postProcessingAssets,
                  postProcessing,
                  uploadId,
                  jobTitle,
                  matchFuel,
                  keepKMZEnabled,
                  wfb,
                  simulationDate,
                )
          }
          variant="contained"
        >
          {processMessage}
        </Button>
      </div>
    </div>
  );
}
