import { useApolloClient } from '@apollo/client';
import { Button, Checkbox } from '@mui/material';
import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import Dropzone from 'react-dropzone-uploader';

import {
  formatBurnTimes,
  getFlatDate,
  getS3DownloadLink,
  getUploadParams,
  jobFieldKeys,
  JobFieldsType,
  JobFieldData,
} from 'src/util';

import style from './style.module.css';

let disablePickers = true;

export default (props: {
  checkHandler?: (key: keyof typeof jobFieldKeys, data: JobFieldsType, jobIndex?: number) => void;
  data: JobFieldData;
  uploadId: string;
  wfb: boolean;
}) => {
  const apollo = useApolloClient();
  const [generatedLinks, setGeneratedLinks] = useState(
    Object.keys(jobFieldKeys).reduce(
      (acc, key) => ({
        ...acc,
        [key]: Array(props.data[key as keyof typeof jobFieldKeys].length).fill({ link: '' }),
      }),
      {} as { [key in keyof typeof jobFieldKeys]: { link: string; expiry?: Date }[] },
    ),
  );

  const selectKeys = props.wfb ? Object.entries(jobFieldKeys).filter(([key, title]) => key !== "fireHistory") : Object.entries(jobFieldKeys);

  return (
    <div className={style.jobFieldList}>
      {selectKeys.map(([key, title]) => {
        const jobFieldValue = props.data[key as keyof typeof jobFieldKeys];

        return (
          <div className={style.jobFieldContainer} key={key}>
            <div className={style.jobFieldText}>
              <h3>{title}</h3>
              <p>{jobFieldValue.filter(({ checked }) => checked).length} selected</p>
              <ul className={style.jobFieldItemList}>
                {jobFieldValue.map((data, jobIndex) => (
                  <li className={style.jobFieldItemListItem} key={`${data.bucket}/${data.key}`}>
                    <div className={style.jobFieldItemListItemText}>
                      {data.title}
                      {key === 'fireHistory' && (
                        <>
                          <hr />
                          {props.checkHandler ? disablePickers=false : disablePickers=true }
                          <>
                            <div className={style.addDateText}>Add Date</div>
                            <div className={style.addDateWrapper}>
                              {data.burnTimes!.map((time, timeIndex) => (
                                <div className={style.addDateDates} key={`${data.title}-${time}`}>
                                  <ReactDatePicker
                                    disabled={disablePickers}
                                    className={style.addDateDate}
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date: Date) =>
                                      props.checkHandler!(
                                        key,
                                        {
                                          ...data,
                                          newBurnTime: { ...data.newBurnTime!, date },
                                          burnTimes: [formatBurnTimes({ ...data.newBurnTime!, date })],
                                        },
                                        jobIndex,
                                      )
                                    }
                                    selected={new Date(time.date.split("/").reverse().join("/"))}
                                  />
                                  <ReactDatePicker
                                    disabled={disablePickers}
                                    dateFormat="HH:mm"
                                    timeFormat="HH:mm"
                                    onChange={(startTime: Date) =>
                                      props.checkHandler!(
                                        key,
                                        {
                                          ...data,
                                          newBurnTime: { ...data.newBurnTime!, startTime },
                                          burnTimes: [formatBurnTimes({ ...data.newBurnTime!, startTime })],
                                        },
                                        jobIndex,
                                      )
                                    }
                                    selected={new Date("1970/01/01 " + time.startTime)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                  />
                                  <ReactDatePicker
                                    disabled={disablePickers}
                                    dateFormat="HH:mm"
                                    timeFormat="HH:mm"
                                    onChange={(endTime: Date) =>
                                      props.checkHandler!(
                                        key,
                                        {
                                          ...data,
                                          newBurnTime: { ...data.newBurnTime!, endTime },
                                          burnTimes: [formatBurnTimes({ ...data.newBurnTime!, endTime })],
                                        },
                                        jobIndex,
                                      )
                                    }
                                    selected={new Date("1970/01/01 " + time.endTime)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                  />
                                </div>
                              ))}
                            </div>
                          </>
                        </>
                      )}
                      {props.checkHandler ? undefined : (
                        <>
                          <Button
                            className={style.downloadLinkButton}
                            color="primary"
                            onClick={() => {
                              setGeneratedLinks({
                                ...generatedLinks,
                                [key]: generatedLinks[key as keyof typeof jobFieldKeys].map(
                                  (linkData, linkIndex) => ({
                                    ...linkData,
                                    link: jobIndex === linkIndex ? '' : linkData.link,
                                  }),
                                ),
                              });

                              getS3DownloadLink(apollo, data.bucket, data.key).then((url) =>
                                setGeneratedLinks({
                                  ...generatedLinks,
                                  [key]: generatedLinks[key as keyof typeof jobFieldKeys].map(
                                    (linkData, linkIndex) => ({
                                      link: jobIndex === linkIndex ? url : linkData.link,
                                      expiry: new Date(),
                                    }),
                                  ),
                                }),
                              );
                            }}
                            size="small"
                            variant="contained"
                          >
                            {generatedLinks[key as keyof typeof jobFieldKeys][jobIndex].expiry
                              ? 'Regenerate download link'
                              : 'Create download link'}
                          </Button>
                          {generatedLinks[key as keyof typeof jobFieldKeys][jobIndex].link && (
                            <div className={style.assetDownloader}>
                              <a
                                href={
                                  generatedLinks[key as keyof typeof jobFieldKeys][jobIndex].link
                                }
                                rel="noreferrer"
                                target="_blank"
                              >
                                Download asset
                              </a>
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    <Checkbox
                      color="secondary"
                      checked={data.checked}
                      disabled={!props.checkHandler}
                      onChange={(event, checked) =>
                        props.checkHandler!(
                          key as keyof typeof jobFieldKeys,
                          {
                            ...data,
                            checked,
                          },
                          jobIndex,
                        )
                      }
                    />
                  </li>
                ))}
              </ul>
            </div>
            {props.checkHandler && (
              <Dropzone
                canRemove={false}
                getUploadParams={getUploadParams.bind(this, apollo, props.uploadId)}
                onChangeStatus={(file, status) =>
                  status === 'uploading'
                    ? props.checkHandler!(key as keyof typeof jobFieldKeys, {
                        ...(key === 'fireHistory'
                          ? {
                              burnTimes: [formatBurnTimes(getFlatDate())],
                              newBurnTime: getFlatDate(),
                            }
                          : {}),
                        bucket: process.env.REACT_APP_FILE_NEW_UPLOADS_BUCKET!,
                        checked: true,
                        file,
                        key: `${props.uploadId}/${file.meta.name}`,
                        title: file.meta.name,
                      })
                    : (() => {
                        const index = jobFieldValue.findIndex(
                          (item) =>
                            item.bucket === process.env.REACT_APP_FILE_NEW_UPLOADS_BUCKET &&
                            item.key === `${props.uploadId}/${file.meta.name}`,
                        );

                        props.checkHandler!(
                          key as keyof typeof jobFieldKeys,
                          { ...jobFieldValue[index], file },
                          index,
                        );
                      })()
                }
                PreviewComponent={(previewProps) => (
                  <div className="dzu-previewContainer">
                    <div className={style.dzuFileName}>{previewProps.meta.name}</div>
                    <div className="dzu-previewStatusContainer">
                      <progress max="100" value={previewProps.meta.percent}></progress>
                    </div>
                  </div>
                )}
                submitButtonContent={null}
                inputContent={`Upload custom ${title}`}
                inputWithFilesContent={`Upload custom ${title}`}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
