import React, { useContext, createContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

export interface User {
  username: string;
  signInUserSession?: {
    accessToken?: {
      jwtToken?: string;
    };
  };
}

const UserContext = createContext<User>(null!);

export function useAuth(): User {
  const user = useContext(UserContext);

  if (typeof user === 'undefined') {
    throw new Error('useAuth must be used within a UserContext');
  }

  return user;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | undefined>(undefined);
  useEffect(() => {
    const get = async function () {
      const cuser = await Auth.currentAuthenticatedUser();
      setUser(cuser);
    };
    get();
  }, []);

  if (!user) {
    return null;
  }

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}
