import { Button, Modal } from '@mui/material';
import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Navigate } from 'react-router-dom';
import style from './style.module.css';
import { LambdaResponse } from 'src/util';
  
interface Props {
  title: string;
  project_ids: string[];
  status: string;
  redirect?: boolean;
}
  
const DeleteJobButton = ({ title, project_ids, status, redirect }: Props) => {
  const [open, setOpen] = useState(false);

  const deleteJobQuery = gql`
    mutation DeleteJob($project_ids: [String!]!) {
      deleteJob(title: "${title}", project_ids: $project_ids) {
        body
        statusCode
      }
    }
  `;

  const [deleteJob, { data, loading, error }] = useMutation(deleteJobQuery,
    { 
      errorPolicy: 'all',
      refetchQueries: ['ListJobs']
    });
  if (error) {
    if (error.message === 'Execution timed out.') {
      if (redirect) {
        return (<Navigate to={`/job/list`} />)
      }
    }
    console.error(`Error deleting job ${title}:`, error);
  }
  if (data) {
    if ((data.deleteJob as LambdaResponse).statusCode!=="200") {
      console.error((data.deleteJob as LambdaResponse).body);
    }
    if (redirect) {
      return (<Navigate to={`/job/list`} />)
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleYes = async () => {
    deleteJob({ variables: { project_ids: project_ids } });
    setOpen(false);
  };

  const handleNo = () => {
    setOpen(false);
  };

  const statuses = [
    'Cancelled',
    'Post-Processing Complete',
    'Error',
    '-1 post-processes remaining',
    '-2 post-processes remaining',
    '-3 post-processes remaining',
    '-4 post-processes remaining',
    'Deleting',
    'Terminated',
    'Timed Out'
  ]

  const invalidStatuses = [
    'Phoenix Processing Complete',
    'Post-Processing Started',
    '0 post-processes remaining'
  ]
  if (!statuses.some(word => status.includes(word)) || invalidStatuses.some(word => status.includes(word))) {
    return (<></>);
  }
  if (loading) {
    return (<Button
      disabled
      color="secondary"
      style={{ display: 'block', width: 'max-content' }}
      variant="contained"
    >
      Deleting...
    </Button>);
  }
  return (
    <>
      <Button
        color="secondary"
        onClick={handleClickOpen}
        style={{ display: 'block', width: 'max-content' }}
        variant="contained"
      >
        Delete Job
      </Button>
      <Modal
        open={open}
        onClose={handleNo}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={style.cancelModalContent}>
          <h2 id="simple-modal-title">{`Delete ${title} job?`}</h2>
          <p id="simple-modal-description">
            This will delete all data related for all current jobs in this group, are you sure?
          </p>
          <div className={style.cancelModalButtons}>
            <Button onClick={handleYes} color="secondary">
              Yes
            </Button>
            <Button onClick={handleNo} color="primary" autoFocus>
              No
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
  
export default DeleteJobButton;  