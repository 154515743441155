import { gql, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from '@mui/material';
import { useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModellingJobs } from 'src/util';
import { v4 } from 'uuid';
import style from './style.module.css';
import LabelBorder from '../LabelBorder';
import { useAuth } from 'src/hooks/useAuth';

export function Submit(props: {
  isWFB: boolean;
  isError: boolean;
  fuelHazardFile: string;
  selected: ModellingJobs[];
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setWFB: (wfb: boolean) => void;
}) {
  const user = useAuth();
  const { isError, fuelHazardFile, selected, setErrorMessage, setWFB } = props;
  const [runTitle, setRunTitle] = useState<string>('');
  const [submitText, setSubmitText] = useState('Run Bayes Net');
  const [modellingId] = useState(v4().replace(/-/g, ''));
  const [legacy, setLegacy] = useState(false);
  const [configValue, setConfigValue] = useState('standard');
  const navigate = useNavigate();

  const [modelling] = useMutation(
    gql`
    mutation Modelling(
      $likelihood: Boolean!,
      $wfb: Boolean!,
      $suppression: Boolean!,
      $legacy: Boolean!) {
      modellingRunStart(
        id: "${modellingId}",
        creator: "${user.username}",
        job_ids: ${JSON.stringify(selected.map(({ projectname }) => projectname))},
        title: "${runTitle}",
        old_house_loss: $legacy,
        likelihood: $likelihood,
        wfb: $wfb,
        suppression: $suppression,
        fuel_hazard_file: "${fuelHazardFile}"
        ) {
        statusCode
        body
      }
    }
  `,
    {
      refetchQueries: ['ModellingRuns'],
    },
  );

  const getModellingConfigVariables = () => {
    return {
      likelihood: configValue === 'likelihood' || configValue === 'worst',
      wfb: configValue === 'worst',
      suppression: configValue === 'uncontained',
      legacy: legacy,
    };
  };

  const submitModellingRun = () => {
    setSubmitText('Processing...');
    modelling({ variables: getModellingConfigVariables() })
      .then((value) => {
        const statusCode = value.data.modellingRunStart.statusCode;
        const body = value.data.modellingRunStart.body;
        if (statusCode === '200') {
          setSubmitText('Run Bayes Net');
          navigate(`/modelling/${modellingId}`);
        } else {
          setSubmitText('Run Bayes Net');
          setErrorMessage(body);
        }
      })
      .catch((err) => {
        if (err.message === 'Execution timed out.') {
          setErrorMessage(err.message);
          setSubmitText('Run Bayes Net');
          navigate(`/modelling/${modellingId}`);
        } else {
          setErrorMessage(err.message);
        }
      });
  };

  const handleConfigChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (configValue === 'worst') {
      setWFB(false);
    }

    const newValue = (event.target as HTMLInputElement).value;
    if (newValue === 'worst') {
      setWFB(true);
    }

    setConfigValue(newValue);
  };

  return (
    <Fragment>
      <div className={style.headerWrapper}>
        <h1>Create Bayes Net Run</h1>
        <div className={style.idWrapper}>ID: {modellingId}</div>
      </div>
      <div className={style.headerWrapper}>
        <TextField
          error={runTitle === ''}
          className={style.textFilter}
          label="Run Title"
          onChange={(event) => setRunTitle(event.target.value)}
          variant="outlined"
        />
        <LabelBorder label="Configuration">
          <RadioGroup
            className={style.radioGroup}
            row={true}
            value={configValue}
            onChange={handleConfigChange}
          >
            <Tooltip title="Dynamic ignition and fire">
              <FormControlLabel value="standard" control={<Radio />} label="Standard Run" />
            </Tooltip>
            <Tooltip title="Guaranteed ignition and unsuppressed fire">
              <FormControlLabel value="worst" control={<Radio />} label="Worse Case" />
            </Tooltip>
            <Tooltip title="Guaranteed ignition likelihood, Used to test suppression strategies">
              <FormControlLabel
                value="likelihood"
                control={<Radio />}
                label="Guaranteed Ignition"
              />
            </Tooltip>
            <Tooltip title="Sets suppression to uncontained">
              <FormControlLabel value="uncontained" control={<Radio />} label="Uncontained Fire" />
            </Tooltip>
          </RadioGroup>
          <Tooltip title="Legacy Houseloss">
            <FormControlLabel
              className={style.checkboxConfig}
              control={
                <Checkbox
                  checked={legacy}
                  onChange={(event, checked) => setLegacy(checked)}
                  color="primary"
                />
              }
              label="Legacy Houseloss"
            />
          </Tooltip>
        </LabelBorder>
        <Button
          disabled={isError || runTitle === '' || submitText === 'Processing...'}
          color="primary"
          onClick={submitModellingRun}
          variant="contained"
        >
          {submitText}
        </Button>
      </div>
    </Fragment>
  );
}
