import style from './style.module.css';

interface props {
  children: any
  label: string
  className: string
}

const defaultProps: props = {
  children: undefined,
  label: "",
  className: ""
}

const LabelBorder = ({ children, label, className } : props) => {
  return (
    <div className={[style.configuration,className].join(' ')} >
      {children}
      <fieldset className={style.configurationFieldSet} >
        <legend className={style.configurationLegend} >
          <span>{label}</span>
        </legend>
      </fieldset>
    </div>
    )
  };

LabelBorder.defaultProps = defaultProps;

export default LabelBorder;