import { gql, useQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  FormLabel,
  Modal,
  Paper,
  TextField,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { StyledTableRow } from 'src/components/StyledTable';
import { format } from 'date-fns';
import { useState, Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModellingJobs, ModellingData } from 'src/util';
import { FuelHazard } from './fuelHazard';
import { Submit } from './submit';

import style from './style.module.css';

function queryString(currentPage: number, pageCount: number, filters: string) {
  return gql`
  query {
    listProjectsForModelling(page: ${currentPage - 1}, pageCount: ${pageCount}, ${filters}) {
      projectname
      weather
      title
      status
      simulation_date
      group_project_id
      grid
      fuellayer
      firehistory
    }
    countProjectsForModelling(${filters}) {
      count
    }
    modellingFuelHazard(operation: "FuelHazard") {
      body
      statusCode
    }
    modellingStatus {
      body
      statusCode
    }

  }
`;
}

export function ModellingSelect() {
  const search = useLocation().search;
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);
  const [pageCount, setPageCount] = useState(parseInt(queryParams.get('l') || '10'));
  const [textFilter, setTextFilter] = useState(queryParams.get('q') || '');
  const [fuelFilter, setFuelFilter] = useState(queryParams.get('f') || '');
  const [fireHistory, setFireHistoryFilter] = useState(queryParams.get('f') || '');
  const [gridFilter, setGridFilter] = useState(queryParams.get('g') || '');
  const [fuelHazardFile, setFuelHazardFile] = useState('');
  const [currentPage, setCurrentPage] = useState(parseInt(queryParams.get('p') || '1'));
  const [selected, setSelected] = useState<ModellingJobs[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isWFB, setWFB] = useState<boolean>(false);

  const updateWFB = (wfb: boolean) => {
    setWFB(wfb);
    const simulation_value = wfb
      ? 'Max'
      : selected[0]
      ? selected[0].firehistory === ''
        ? 'Max'
        : selected[0].simulation_date.substring(0, 4)
      : '';
    setFuelHazardFile(`Fuelhazard_${simulation_value}.tif`);
  };

  const filters = `matchText:"${textFilter}",
    fireHistory:"${fireHistory}",
    fuelLayer:"${fuelFilter}",
    grid:"${gridFilter}"`;

  const isGroupSelected = (groupId: string) =>
    selected.filter(({ group_project_id }) => group_project_id === groupId).length > 0;
  const isSelected = (projectName: string) =>
    selected.filter(({ projectname }) => projectname === projectName).length > 0;
  const isError = selected.length < 5 || selected.length > 50;

  const { data, error, loading } = useQuery<ModellingData>(
    queryString(currentPage, pageCount, filters),
  );

  const updateSelected = (jobs: ModellingJobs[]) => {
    if (selected.length === 0 && jobs.length >= 1) {
      setFuelFilter(jobs[0].fuellayer.split('/').pop() || '');
      setFireHistoryFilter(jobs[0].firehistory.split('/').pop() || '');
      setGridFilter(jobs[0].grid.split('/').pop() || '');
      setCurrentPage(1);
      queryParams.set('p', '1');
      const wfb = jobs[0].firehistory === '';
      const simulation_value = wfb ? 'Max' : jobs[0].simulation_date.substring(0, 4);
      setFuelHazardFile(`Fuelhazard_${simulation_value}.tif`);
      setWFB(wfb);
    } else if (jobs.length === 0) {
      setFuelFilter('');
      setFireHistoryFilter('');
      setGridFilter('');
      setFuelHazardFile('');
      setWFB(false);
    }

    setSelected(jobs);
  };

  const onAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const isChecked = event.target.checked;

    if (isChecked && !!data) {
      updateSelected(data.listProjectsForModelling);
      return;
    }

    updateSelected([]);
  };

  const onGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const groupId = event.target.value;

    if (!!data) {
      const selectedGroup = selected.filter(({ group_project_id }) => group_project_id === groupId);
      const jobsGroup = data.listProjectsForModelling.filter(
        ({ group_project_id }) => group_project_id === groupId,
      );
      const newSelected: ModellingJobs[] = selected.filter(
        ({ group_project_id }) => group_project_id !== groupId,
      );

      if (selectedGroup.length !== jobsGroup.length) {
        updateSelected(newSelected.concat(jobsGroup));
        return;
      }

      updateSelected(newSelected);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const projectId = event.target.value;

    const selectedIndex = selected.findIndex(({ projectname }) => projectname === projectId);

    let newSelected: ModellingJobs[] = [];

    if (selectedIndex === -1) {
      const job = data?.listProjectsForModelling.filter(
        ({ projectname }) => projectname === projectId,
      );
      newSelected = (!!job && newSelected.concat(selected, job)) || [];
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    updateSelected(newSelected);
  };

  const closeErrorModal = () => {
    setErrorMessage('');
  };

  return (
    <div className={style.modellingSelect}>
      <Modal open={errorMessage !== ''} onClose={closeErrorModal}>
        <div className={style.errorModalContent}>
          <p>{errorMessage}</p>
          <Button
            className={style.errorModalButton}
            color="secondary"
            onClick={closeErrorModal}
            variant="contained"
          >
            Close
          </Button>
        </div>
      </Modal>
      <Submit
        isWFB={isWFB}
        isError={isError}
        selected={selected}
        setErrorMessage={setErrorMessage}
        setWFB={updateWFB}
        fuelHazardFile={fuelHazardFile}
      />
      <div className={style.filterWrapper}>
        <TextField
          className={style.textFilter}
          label="Search"
          onChange={(event) => {
            const filterValue = event.target.value.replace(/\\/g, '');
            setTextFilter(filterValue);
            filterValue
              ? queryParams.set('q', event.target.value.replace(/\\/g, ''))
              : queryParams.delete('q');
            queryParams.set('p', '1');
            navigate({ search: queryParams?.toString() });
            setCurrentPage(1);
          }}
          value={textFilter}
          variant="outlined"
        />
        {data && data.modellingFuelHazard && fuelHazardFile && (
          <FuelHazard
            fuelHazardFile={fuelHazardFile}
            fuelHazardFiles={JSON.parse(data?.modellingFuelHazard.body)['files']}
            setFuelHazardFile={setFuelHazardFile}
          />
        )}
        <FormControl className={style.results} sx={{ m: 1, minWidth: 60 }}>
          <InputLabel>Results</InputLabel>
          <Select
            value={pageCount}
            label="Results"
            inputProps={{
              name: 'results',
            }}
            onChange={(event) => {
              const results = (event.target.value || '10') as string;
              setPageCount(parseInt(results));
              queryParams.set('l', results);
              queryParams.set('p', '1');
              navigate({ search: queryParams?.toString() });
              setCurrentPage(1);
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </div>
      {error && 'Error!'}
      {!loading && !error && !!data ? (
        <>
          <Paper className={style.modellingTable}>
            {!!fuelFilter && (
              <div className={style.filterHeader}>
                <div className={style.filterTitle}>Filters:</div>
                <div>
                  <b>Fuel Layer:</b> {fuelFilter}
                </div>
                <div>
                  <b>Fire History:</b> {fireHistory}
                </div>
                <div>
                  <b>Ignition Grid:</b> {gridFilter}
                </div>
              </div>
            )}
            <div className={style.selectedHeader}>
              <FormLabel error={isError}>
                <b>Selected:</b> {selected.length}
              </FormLabel>
              <FormLabel error={isError} className={isError ? '' : style.noErrorMessage}>
                <b>Error:</b> Select between 5 and 50 jobs to be modelled
              </FormLabel>
            </div>
            <ExpandedTable
              jobs={data.listProjectsForModelling}
              selected={selected}
              isGroupSelected={isGroupSelected}
              isSelected={isSelected}
              onAllChange={onAllChange}
              onGroupChange={onGroupChange}
              onChange={onChange}
            />
          </Paper>
          <Pagination
            boundaryCount={3}
            className={style.pagination}
            count={Math.max(1, Math.ceil(data.countProjectsForModelling[0].count / pageCount))}
            onChange={(event, page) => {
              queryParams.set('p', page.toString());
              navigate({ search: queryParams?.toString() });
              setCurrentPage(page);
            }}
            page={currentPage}
            siblingCount={5}
          />
          <div className={style.modellingFooter}>
            <div>
              Netica Code:{' '}
              {format(
                new Date(JSON.parse(data.modellingStatus.body)['image_pushed']),
                'yyyy/MM/dd HH:mm:ss',
              )}
            </div>
            <div>
              Trained Netica Model:{' '}
              {format(
                new Date(JSON.parse(data.modellingStatus.body)['s3_model_last_modified']),
                'yyyy/MM/dd HH:mm:ss',
              )}
            </div>
          </div>
        </>
      ) : (
        'Loading ...'
      )}
    </div>
  );
}

function ExpandedTable({
  jobs,
  selected,
  isGroupSelected,
  isSelected,
  onAllChange,
  onGroupChange,
  onChange,
}: {
  jobs: ModellingJobs[];
  selected: ModellingJobs[];
  isGroupSelected: (groupId: string) => boolean;
  isSelected: (projectName: string) => boolean;
  onAllChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onGroupChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  interface ExtendedModellingHeader {
    title: string;
    group_project_id: string;
    jobs: ModellingJobs[];
    [key: string]: string | ModellingJobs[];
  }

  const expandedData = jobs
    .filter(
      (val, ind, arr) =>
        arr.findIndex((findVal) => findVal.group_project_id === val.group_project_id) === ind,
    )
    .map((val) => ({
      ...val,
      jobs: jobs.filter((raw) => raw.group_project_id === val.group_project_id).map((raw) => raw),
    })) as ExtendedModellingHeader[];

  const ExpandedHeaderKeys = {
    title: 'Title',
    group_project_id: 'Group Id',
    status: 'Status',
  };

  const ExpandedRows = {
    projectname: 'Project Id',
    weather: 'Weather',
    grid: 'Ignition Grid',
    firehistory: 'Fire History',
    fuellayer: 'Fuel Layer',
    simulation_date: 'Simulation Date',
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <StyledTableRow>
            <TableCell className={style.checkboxCell}>
              <Checkbox
                disabled={selected.length === 0}
                value="all"
                checked={selected.length > 0}
                onChange={onAllChange}
                color="primary"
                indeterminate={selected.length > 0}
              />
            </TableCell>
            {Object.values(ExpandedHeaderKeys).map((header) => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {(expandedData || []).map((headerRow) => (
            <Fragment key={`${headerRow.group_project_id}-fragment`}>
              <StyledTableRow>
                <TableCell className={style.checkboxCell}>
                  <Checkbox
                    value={headerRow.group_project_id}
                    checked={isGroupSelected(headerRow.group_project_id)}
                    onChange={onGroupChange}
                    color="primary"
                    indeterminate={
                      selected.filter(
                        ({ group_project_id }) => group_project_id === headerRow.group_project_id,
                      ).length > 0 &&
                      selected.filter(
                        ({ group_project_id }) => group_project_id === headerRow.group_project_id,
                      ).length <
                        jobs.filter(
                          ({ group_project_id }) => group_project_id === headerRow.group_project_id,
                        ).length
                    }
                  />
                </TableCell>
                {Object.keys(ExpandedHeaderKeys).map((field) => (
                  <TableCell key={`${field}-cell`}>{headerRow[field].toString()}</TableCell>
                ))}
              </StyledTableRow>
              <StyledTableRow className={style.subRow}>
                <TableCell colSpan={4}>
                  <Table size="small">
                    <TableHead>
                      <StyledTableRow>
                        <TableCell colSpan={2}></TableCell>
                        {Object.values(ExpandedRows).map((header) => (
                          <TableCell key={header}>{header}</TableCell>
                        ))}
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {(headerRow['jobs'] || []).map((row) => (
                        <StyledTableRow key={`${row.projectname}-row`}>
                          <TableCell></TableCell>
                          <TableCell className={style.checkboxCell}>
                            <Checkbox
                              value={row.projectname}
                              checked={isSelected(row.projectname)}
                              onChange={onChange}
                              color="primary"
                            />
                          </TableCell>
                          {Object.keys(ExpandedRows).map((field) => (
                            <TableCell
                              key={`${field}-cell`}
                              className={style.subCell}
                              title={row[field]}
                            >
                              {row[field].split('/').pop()}
                            </TableCell>
                          ))}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableCell>
              </StyledTableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
