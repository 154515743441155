import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import style from './style.module.css';

export function FuelHazard(props: {
    fuelHazardFile:string,
    fuelHazardFiles:string[] | undefined,
    setFuelHazardFile:React.Dispatch<React.SetStateAction<string>>,
}) {
    const { fuelHazardFile, fuelHazardFiles, setFuelHazardFile } = props;

    if (fuelHazardFile && fuelHazardFiles) {
        let selectedValue = fuelHazardFile;
        if (fuelHazardFiles.indexOf(fuelHazardFile) === -1) {
            selectedValue = fuelHazardFiles[0];
            setFuelHazardFile(fuelHazardFiles[0]);
        }

        return (
            <FormControl className={style.fuelHazard} sx={{ m: 1 }}>
                <InputLabel id="demo-simple-select-label">Selected Fuel Hazard Files</InputLabel>
                <Select
                    value={selectedValue}
                    label="Fuel Hazard Files"
                    onChange={(event) => {
                      setFuelHazardFile(event.target.value);
                    }}
                >
                    {fuelHazardFiles.map((fuelHazard) => (
                        <MenuItem key={fuelHazard} value={fuelHazard}>{fuelHazard}</MenuItem>
                    ))}
                </Select>
            </FormControl>);
    }

    return (<></>);
}