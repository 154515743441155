import { Route, Routes } from 'react-router-dom';

import { Container } from '@mui/material';

import JobList from 'src/components/JobList';
import Modelling from 'src/components/Modelling';
import { ModellingSelect } from 'src/components/ModellingSelect';
import ModellingList from 'src/components/ModellingList';
import JobDetail from 'src/components/JobDetail';
import { UploadForm } from 'src/components/UploadForm';
import Header from 'src/components/Header';

export function Main() {
  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Routes>
          <Route path="/job/new" element={<UploadForm />} />
          <Route path="/job/new/copyFrom/:copyId" element={<UploadForm />} />
          <Route path="/job/list" element={<JobList />} />
          <Route path="/job/id/:jobId" element={<JobDetail />} />
          <Route path="/modelling/:modelling_id" element={<Modelling />} />
          <Route path="/modelling" element={<ModellingSelect />} />
          <Route path="/modellingList" element={<ModellingList />} />
        </Routes>
      </Container>
    </>
  );
}
