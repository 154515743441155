import { AppBar, Container, Tab, Tabs, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Amplify } from 'aws-amplify';

const environment = process.env.REACT_APP_ENVIRONMENT;

export default () => {
  const logout = () => {
    Amplify.Auth.signOut();
  };
  return (
    <AppBar style={{ background: '#201647' }} position="sticky">
      <Container maxWidth={false} sx={{ display: 'flex' }}>
        <Tabs value={false} variant="fullWidth" sx={{ flex: 1 }}>
          <Tab label="Create new job" component={Link} to="/job/new" />
          <Tab label="View jobs list" component={Link} to="/job/list" />
          <Tab label="Create Bayes Net" component={Link} to="/modelling" />
          <Tab label="View Bayes Net runs" component={Link} to="/modellingList" />
        </Tabs>
        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', color: '#FCE94F', padding: '0 16px' }}>
          Env: {environment}
        </Typography>
        <Button
          variant="contained"
          onClick={logout}
          endIcon={<AccountCircleIcon sx={{ color: '#FFF' }} />}
        >
          Logout
        </Button>
      </Container>
    </AppBar>
  );
};
