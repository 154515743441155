import { gql, useQuery } from '@apollo/client';
import {
  Paper,
  Select,
  FormControl,
  InputLabel,
  Button,
  Checkbox,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TextField,
  Tooltip
} from '@mui/material';
import { StyledTableRow } from 'src/components/StyledTable';
import { format } from 'date-fns';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ModellingCancel from '../ModellingCancel';
import { ModellingListKeys } from 'src/util';
import LabelBorder from '../LabelBorder';

import style from './style.module.css';

export default () => {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const search = useLocation().search;
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);
  const [pageCount, setPageCount] = useState(parseInt(queryParams.get('l') || '10'));
  const [currentPage, setCurrentPage] = useState(parseInt(queryParams.get('p') || '1'));
  const [matchText, setMatchText] = useState(queryParams.get('q') || '');
  const [showChecked, setShowChecked] = useState(false);

  const { data, error, loading } = useQuery(
    gql`
      query ModellingRuns {
        getModellingRuns(
          page: ${currentPage - 1},
          pageCount: ${pageCount},
          cancelled: ${showChecked},
          matchText: "${matchText}"
        ) {
          create_date
          update_date
          modelling_id
          status
          title
          execution_arn
        }
        countModellingRuns(
          cancelled: ${showChecked},
          matchText: "${matchText}"
        ) {
          count
        }
      }
    `,
  );

  const modelDatabase = (title:string, modelling_id:string) => {
    const athena_database_pre = environment === "test" ? "test_bn_" : "bn_";
    const replace_title = title.replace(/[^A-Za-z0-9]/g,'_');
    return `${athena_database_pre}${replace_title}_${modelling_id}`;
  }

  return (
    <div className={style.modellingList}>
      <div className={style.filterWrapper}>
        <h1>Modelling list</h1>
      </div>
      <div className={style.filterWrapper}>
        <TextField
          className={style.textFilter}
          label="Text filter"
          onChange={(event) => {
            const filterValue = event.target.value.replace(/\\/g, '');
            setMatchText(filterValue);
            filterValue?queryParams.set('q',event.target.value.replace(/\\/g, '')):queryParams.delete('q');
            queryParams.set('p','1');
            navigate({search: queryParams?.toString()});
            setCurrentPage(1);
          }}
          value={matchText}
          variant="outlined"
        />
        <div className={style.filterWrapper}>
          <LabelBorder label="Cancelled" className={style.Label}>
            <Tooltip title="Show cancelled runs">
              <Checkbox 
                color="secondary"
                onChange={(event, checked) => setShowChecked(checked)}
                checked={showChecked} />
            </Tooltip>
          </LabelBorder>
          <FormControl sx={{ m: 1, minWidth: 60 }}>
            <InputLabel>Results</InputLabel>
            <Select
              value={pageCount}
              label="Results"
              inputProps={{
                name: 'results',
              }}
              onChange={(event) => {
                const results=(event.target.value||'10') as string;
                setPageCount(parseInt(results));
                queryParams.set('l',results);
                queryParams.set('p','1');
                navigate({search: queryParams?.toString()});
                setCurrentPage(1);
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      {error && 'Error!'}
      {!loading && !error && (
        <Paper className={style.modellingTable}>
          <TableContainer>
            <Table aria-label="modelling list">
              <TableHead>
                <StyledTableRow>
                  {['Title','Status','Create Date','Update Date','Actions'].map((title) => (
                    <TableCell key={title}>{title}</TableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {(data.getModellingRuns || []).map((row:ModellingListKeys) => (
                  <StyledTableRow key={`${row.modelling_id}-row`}>
                    {(['title', 'status'] as const).map((field) => (
                      <TableCell key={`${field}-cell`} onClick={()=>navigate(`/modelling/${row.modelling_id}`)}>
                        {row[field]}
                      </TableCell>
                    ))}
                    <TableCell onClick={()=>navigate(`/modelling/${row.modelling_id}`)}>
                      {format(new Date(`${row.create_date}+0000`), 'dd/MM/yyyy HH:mm:ss')}
                    </TableCell>
                    <TableCell onClick={()=>navigate(`/modelling/${row.modelling_id}`)}>
                      {format(new Date(`${row.update_date}+0000`), 'dd/MM/yyyy HH:mm:ss')}
                    </TableCell>
                    <TableCell>
                      <ModellingCancel
                        title={row.title}
                        modelling_id={row.modelling_id}
                        execution_arn={row.execution_arn}
                        status={row.status}
                      />
                      {row.status === 'Bayes Net Complete' && (
                        <Button
                          target="_blank"
                          variant="contained"
                          href={`https://ap-southeast-2.console.aws.amazon.com/athena/home?region=ap-southeast-2#preview/${modelDatabase(row.title, row.modelling_id)}/bn_output`}>
                          Query Athena
                        </Button>
                      )}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
      {data ? (
        <Pagination
          boundaryCount={3}
          className={style.pagination}
          count={Math.max(1, Math.ceil(data.countModellingRuns[0].count / pageCount))}
          onChange={(event, page) => {
            queryParams.set('p',page.toString());
            navigate({search: queryParams?.toString()});
            setCurrentPage(page);
          }}
          page={currentPage}
          siblingCount={5}
        />
      ) : (
        'Loading ...'
      )}
    </div>
  );
};
