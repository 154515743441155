import { ApolloClient, gql } from '@apollo/client';
import { format } from 'date-fns';
import { IFileWithMeta } from 'react-dropzone-uploader';

export interface S3Data {
  bucket: string;
  key: string;
};

export interface BurnTimeData<T> {
  date: T;
  endTime: T;
  startTime: T;
};

export interface MetadataFileFields extends S3Data {
  // burnTimes are only set for the fireHistory key
  burnTimes?: BurnTimeData<string>[];
};

export interface JobFieldsType extends MetadataFileFields {
  checked: boolean;
  file?: IFileWithMeta;
  title: string;
  index?: string;

  // This is only set for the fireHistory key, and holds the values of the editable add date fields
  newBurnTime?: BurnTimeData<Date>;
};

export interface PostProcessingSelection {
  basicPostProcessing: boolean,
	criticalInfrastructure: boolean,
	fireSizeMetric: boolean,
	hydrofireWaterQuality: boolean,
	hydrofireWaterYield: boolean,
  primaryProduction: boolean,
}

export interface PostProcessingAssets {
  assetBasicHouseLoss: JobFieldsType[],
  assetCriticalInfrastructure: JobFieldsType[],
}

export interface JobFieldData {
  fireHistory: JobFieldsType[],
  weather: JobFieldsType[],
  ignitionGrid: JobFieldsType[],
  fuelLayer: JobFieldsType[],
  fuelType: JobFieldsType[],
  dem: JobFieldsType[],
  roadProximity: JobFieldsType[],
  disruption: JobFieldsType[],
  windModifiers: JobFieldsType[],
  projectionPath: JobFieldsType[],
}

export interface ModellingJobs {
  projectname: string;
  weather: string;
  title: string;
  status: string;
  simulation_date: string;
  group_project_id: string;
  grid: string;
  fuellayer: string;
  firehistory: string;
  [key: string]: string;
};

interface Counts {
  count: number;
};

export interface ModellingData {
  listProjectsForModelling: ModellingJobs[];
  countProjectsForModelling: Counts[];
  modellingFuelHazard: LambdaResponse;
  modellingStatus: LambdaResponse;
};

export interface ModellingListKeys {
  modelling_id: string,
  title: string,
  status: string,
  create_date: string,
  update_date: string,
  execution_arn: string,
};

export interface ModelData {
  title: string,
  create_date: string,
  modelling_id: string,
  status: string,
  wfb: boolean,
  suppression: boolean,
  likelihood: boolean,
  old_house_loss: boolean,
  fuel_hazard: string,
  execution_arn: string,
  project_id: string[],
  weather: string[],
  grid: string,
  firehistory: string,
  fuellayer: string,
  fueltype: string,
}

export interface LambdaResponse {
  body: string;
  statusCode: string;
}

export type PostProcessingType = { checked: boolean };
export type ProcessMessageType = 'Process Files' | 'Processing ...' | 'Job sent successfully!';

// Convenience function to load a new date in the 3 datepicker field formats
export const getFlatDate = () => ({
  date: new Date(new Date(2022, 1, 16, 0, 0, 0, 0).setFullYear((new Date().getFullYear())+1)),
  endTime: new Date(new Date().setHours(23, 0, 0, 0)),
  startTime: new Date(new Date().setHours(11, 0, 0, 0)),
});

export const formatBurnTimes = (date: BurnTimeData<Date>): BurnTimeData<string> => ({
  date: format(date.date, 'dd/MM/yyyy'),
  endTime: format(date.endTime, 'HH:mm'),
  startTime: format(date.startTime, 'HH:mm'),
});

export const defaultDate = () => {
  return format(new Date(2022, 1, 16, 0, 0, 0, 0), 'dd/MM/yyyy');
}

export const weatherGridKeys = {
  weather: 'Weather',
  ignitionGrid: 'Ignition Grid',
} as const;

export const historyFuelKeys = {
  fireHistory: 'Fire History',
  fuelLayer: 'Fuel Layer',
  fuelType: 'Fuel Type',
} as const;

export const generalKeys = {
  dem: 'DEM',
  roadProximity: 'Road Proximity',
  disruption: 'Disruption',
  windModifiers: 'Wind Modifiers',
  projectionPath: 'Projection Path',
} as const;

export const jobFieldKeys = {
  fireHistory: 'Fire History',
  weather: 'Weather',
  ignitionGrid: 'Ignition Grid',
  fuelLayer: 'Fuel Layer',
  fuelType: 'Fuel Type',
  dem: 'DEM',
  roadProximity: 'Road Proximity',
  disruption: 'Disruption',
  windModifiers: 'Wind Modifiers',
  projectionPath: 'Projection Path',
  // TODO We currently have no inputs for suppression, when that changes we can open it again
  // suppression: 'Suppression',
} as const;

export const assetFieldKeys = {
  assetBasicHouseLoss: 'Asset Basic House Loss',
  assetCriticalInfrastructure: 'Asset Critical Infrastructure',
}

export const postProcessingKeys = {
  basicPostProcessing: 'Basic Post Processing',
  criticalInfrastructure: 'Critical Infrastructure',
  fireSizeMetric: 'Fire Size Metric',
  hydrofireWaterQuality: 'Hydrofire Water Quality',
  hydrofireWaterYield: 'Hydrofire Water Yield',
  primaryProduction: 'Primary Production',
  // noFireHistory: 'No Fire History',
} as const;

export const postProcessingAssetMap = {
  //basicPostProcessing: 'assetBasicHouseLoss',
  criticalInfrastructure: 'assetCriticalInfrastructure'
} as const;

export const jobDetailKeys = {
  projectname: 'Project ID',
  status: 'Status',
  expected_points: 'Expected Points',
  actual_points: 'Run Points',
  error_points: 'Error Points',
  project_started: 'Start Time',
} as const;

export const JobDetailKeysExtended = {
  ...jobDetailKeys,
  nfh: 'NFH',
  nfh_id: 'NFH ID',
  errors: 'Errors in run',
  simulation_weather: 'Weather',
  simulation_master_grid: 'Grid',
  simulation_firehistory: 'Fire History',
  simulation_fuellayer: 'Fuel Layer',
  simulation_fueltype: 'Fuel Type',
};

// TODO This is in the format of the react-dropzone-uploader library as that's how it was first used, but signing happens in a number of places now
// https://react-dropzone-uploader.js.org/docs/s3
export const getUploadParams = async (
  apollo: ApolloClient<object>,
  id: string,
  { file, meta: { name } }: IFileWithMeta,
) => {
  const { data } = await apollo
    .mutate({
      mutation: gql`
        mutation {
          getPresignedS3Url(bucket: "${process.env.REACT_APP_FILE_NEW_UPLOADS_BUCKET}", contentType: "${file.type}", fileKey: "${id}/${name}", operation: "putObject") {
            body
            statusCode
          }
        }
      `,
    })
    .catch((error) => {
      console.error('Error generating presigned S3 url for upload:', error);

      throw error;
    });

  return {
    body: file,
    method: 'PUT' as const,
    url: data.getPresignedS3Url.body,
  };
};

export const getS3DownloadLink = async (
  apollo: ApolloClient<object>,
  bucket: string,
  key: string,
) => {
  const { data } = await apollo
    .mutate({
      mutation: gql`
        mutation {
          getPresignedS3Url(bucket: "${bucket}", fileKey: "${key}", operation: "getObject") {
            body
            statusCode
          }
        }
      `,
    })
    .catch((error) => {
      console.error('Error generating presigned S3 url for upload:', error);

      throw error;
    });

  return data.getPresignedS3Url.body;
};
