import { GraphQLProvider } from 'src/hooks/useGraphql';

import { Router } from './router';
import { ThemeProvider } from 'src/hooks/useTheme';
import { Amplify } from 'aws-amplify';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { AuthProvider } from 'src/hooks/useAuth';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true,
  },
});

function App(props: any) {
  return (
    <AuthProvider>
      <GraphQLProvider url={process.env.REACT_APP_APPSYNC_URL}>
        <ThemeProvider>
          <Router />
        </ThemeProvider>
      </GraphQLProvider>
    </AuthProvider>
  );
}

// Wrap the app with the Amplify Authenticator
export default withAuthenticator(App, { hideSignUp: true });
