import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { useAuth } from 'src/hooks/useAuth';

export function GraphQLProvider({ url, children }: { url?: string; children: React.ReactNode }) {
  const user = useAuth();
  if (!user?.signInUserSession?.accessToken?.jwtToken) {
    return null;
  }

  const apollo = new ApolloClient({
    uri: url,
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
    },
    headers: user?.signInUserSession?.accessToken?.jwtToken
      ? { authorization: user?.signInUserSession?.accessToken?.jwtToken }
      : {},
  });

  if (!apollo) {
    return null;
  }

  return <ApolloProvider client={apollo}>{children}</ApolloProvider>;
}
