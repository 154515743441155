import { Checkbox, FormControlLabel } from '@mui/material';

import { postProcessingKeys, PostProcessingSelection } from 'src/util';

export default (props: {
  checkHandler?: (key: keyof typeof postProcessingKeys, checked: boolean) => void;
  data: PostProcessingSelection;
}) => (
  <>
    {Object.entries(postProcessingKeys).map(([key, title]) => (
      <FormControlLabel
        control={
          <Checkbox
            color="secondary"
            checked={props.data[key as keyof typeof postProcessingKeys]}
            disabled={!props.checkHandler}
            onChange={
              props.checkHandler &&
              ((event, checked) =>
                props.checkHandler!(key as keyof typeof postProcessingKeys, checked))
            }
          />
        }
        key={`${key}-control`}
        label={title}
      />
    ))}
  </>
);
