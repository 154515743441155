import { Button, Modal } from '@mui/material';
import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import style from './style.module.css';
import { LambdaResponse } from 'src/util';
  
interface Props {
  title: string;
  modelling_id: string; 
  execution_arn: string;
  status: string;
}
  
const ModellingCancel = ({ title, modelling_id, execution_arn, status }: Props) => {
  const [open, setOpen] = useState(false);

  const stopQuery = gql`
    mutation {
      modellingRunStop(modelling_id: "${modelling_id}", execution_arn: "${execution_arn}") {
        body
        statusCode
      }
    }
  `;
  
  const [modellingRunStop, { data, loading, error }] = useMutation(stopQuery,
    { 
      errorPolicy: 'all',
      refetchQueries: ['ModellingRuns']
    });
  if (error) {
    console.error(`Error stopping run ${modelling_id}:`, error);
  }
  if (data) {
    if ((data.modellingRunStop as LambdaResponse).statusCode!=="200") {
      console.error((data.modellingRunStop as LambdaResponse).body);
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleYes = async () => {
    modellingRunStop();
    setOpen(false);
  };

  const handleNo = () => {
    setOpen(false);
  };

  const statuses = [
    'Cancelled',
    'Bayes Net Complete',
    'Error',
  ]
  if (statuses.some(word => status.includes(word))) {
    return (<></>);
  }
  if (loading) {
    return (<Button
      disabled
      color="secondary"
      style={{ display: 'block', width: 'max-content' }}
      variant="contained"
    >
      Cancelling...
    </Button>);
  }
  return (
    <>    
      <Button
        color="secondary"
        onClick={handleClickOpen}
        style={{ display: 'block', width: 'max-content' }}
        variant="contained"
      >
        Cancel Job
      </Button>
      <Modal
        open={open}
        onClose={handleNo}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={style.cancelModalContent}>
          <h2 id="simple-modal-title">{`Cancel ${title} job?`}</h2>
          <p id="simple-modal-description">
            This will cancel the bayes net run, are you sure?
          </p>
          <div className={style.cancelModalButtons}>
            <Button onClick={handleYes} color="secondary">
              Yes
            </Button>
            <Button onClick={handleNo} color="primary" autoFocus>
              No
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
  
export default ModellingCancel;