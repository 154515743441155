import { Button, Modal } from '@mui/material';
import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import style from './style.module.css';
import { LambdaResponse } from 'src/util';
import { Navigate } from 'react-router-dom';

  
interface Props {
  title: string;
  modelling_id: string; 
  status: string;
}
  
const ModellingDelete = ({ title, modelling_id, status }: Props) => {
  const [open, setOpen] = useState(false);

  const deleteQuery = gql`
    mutation {
      modellingRunDelete(modelling_id: "${modelling_id}", operation: "Delete") {
        body
        statusCode
      }
    }
  `;
  
  const [modellingRunDelete, { data, loading, error }] = useMutation(deleteQuery,
    { 
      errorPolicy: 'all',
      refetchQueries: ['ModellingRuns']
    });
  if (error) {
    console.error(`Error deleting job ${modelling_id}:`, error);
  }
  if (data) {
    if ((data.modellingRunDelete as LambdaResponse).statusCode!=="200") {
      console.error((data.modellingRunDelete as LambdaResponse).body);
    }
    return (<Navigate to={`/modellingList`} />)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleYes = async () => {
    modellingRunDelete();
    setOpen(false);
  };

  const handleNo = () => {
    setOpen(false);
  };

  const statuses = [
    'Cancelled',
    'Bayes Net Complete',
    'Error',
  ]
  if (statuses.some(word => status.includes(word))) {
    if (loading) {
      return (<Button
        disabled
        color="secondary"
        style={{ display: 'block', width: 'max-content' }}
        variant="contained"
      >
        Deleting...
      </Button>);
    }
    return (
      <>    
        <Button
          color="secondary"
          onClick={handleClickOpen}
          style={{ display: 'block', width: 'max-content' }}
          variant="contained"
        >
          Delete Job
        </Button>
        <Modal
          open={open}
          onClose={handleNo}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={style.deleteModalContent}>
            <h2 id="simple-modal-title">{`Delete ${title} job?`}</h2>
            <p id="simple-modal-description">
              This will delete the bayes net run, and all run data, are you sure?
            </p>
            <div className={style.deleteModalButtons}>
              <Button onClick={handleYes} color="secondary">
                Yes
              </Button>
              <Button onClick={handleNo} color="primary" autoFocus>
                No
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  }  
  return (<></>);
};
  
export default ModellingDelete;