import './base.module.css';
import { ThemeProvider as MuiThemeProvider, createTheme, CssBaseline } from '@mui/material';

export const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: '1.43',
          letterSpacing: '0.01071em',
          backgroundColor: '#fafafa',
        },
      },
    },
  },
  palette: {
    secondary: {
      main: '#af272f',
    },
  },
});

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}
