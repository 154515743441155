import { Button, Modal } from '@mui/material';
import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import style from './style.module.css';
import { LambdaResponse } from 'src/util';
  
interface Props {
  title: string;
  id: string;
  status: string;
}

const CancelButton = ({ title, id, status }: Props) => {
  const [open, setOpen] = useState(false);

  const deleteQueueQuery = gql`
    mutation {
      deleteQueue(groupId: "${id}") {
        body
        statusCode
      }
    }
  `;

  const [deleteQueue, { data, loading, error }] = useMutation(deleteQueueQuery,
    { 
      errorPolicy: 'all',
      refetchQueries: ['ListJobs']
    });
  if (error) {
    console.error(`Error cancelling queue ${id}:`, error);
  }
  if (data) {
    if ((data.deleteQueue as LambdaResponse).statusCode!=="200") {
      console.error((data.deleteQueue as LambdaResponse).body);
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleYes = async () => {
    deleteQueue();
    setOpen(false);
  };

  const handleNo = () => {
    setOpen(false);
  };

  const statuses = [
    'Successfully prepared the database',
    'Successfully read weather data',
    'Sending SQS messages',
    'Job Created',
    'Phoenix Processing'
  ]
  if (!statuses.some(word => status.includes(word))) {
    return (<></>);
  }
  if (loading) {
    return (<Button
      disabled
      color="secondary"
      style={{ display: 'block', width: 'max-content' }}
      variant="contained"
    >
      Cancelling...
    </Button>);
  }
  return (
    <>    
      <Button
        color="secondary"
        onClick={handleClickOpen}
        style={{ display: 'block', width: 'max-content' }}
        variant="contained"
      >
        Cancel Job
      </Button>
      <Modal
        open={open}
        onClose={handleNo}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={style.cancelModalContent}>
          <h2 id="simple-modal-title">{`Cancel ${title} job?`}</h2>
          <p id="simple-modal-description">
            This will cancel the phoenix run for all current jobs in this group, are you sure?
          </p>
          <div className={style.cancelModalButtons}>
            <Button onClick={handleYes} color="secondary">
              Yes
            </Button>
            <Button onClick={handleNo} color="primary" autoFocus>
              No
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
  
export default CancelButton;  