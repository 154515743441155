import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';

import {
  assetFieldKeys,
  JobFieldsType,
  postProcessingKeys,
  postProcessingAssetMap,
  PostProcessingSelection,
  PostProcessingAssets
} from 'src/util';

export default (props: {
  checkHandler?: (
    key: keyof typeof assetFieldKeys,
    selection: JobFieldsType | null,
  ) => void,
  postProcessingAssets: PostProcessingAssets,
  postProcessing: PostProcessingSelection,
}) => (
  <>
    {
      (Object.keys(postProcessingAssetMap)).map((key) => {
        const assetKey = postProcessingAssetMap[key as keyof typeof postProcessingAssetMap];
        return props.postProcessingAssets[assetKey] && 
          props.postProcessing[key as keyof typeof postProcessingAssetMap] ? (
          <Autocomplete
            key={key}
            disabled={!props.checkHandler}
            getOptionLabel={(data) => data.title}
            isOptionEqualToValue={(option, value) => (option && option.key) === (value && value.key)}
            onChange={props.checkHandler && ((event, value) => props.checkHandler!(assetKey, value))}
            options={props.postProcessingAssets[assetKey] || []}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${postProcessingKeys[key as keyof typeof postProcessingAssetMap]} asset file`}
                variant="outlined"
              />
            )}
            style={{ width: '320px', marginTop: '16px' }}
            value={props.postProcessingAssets[assetKey]?.find((asset) => asset.checked) ?? null} //Magic with null to make it controlled control
          />
        ) : undefined;
      })
    }
  </>
);
