import React from 'react';
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import LabelBorder from '../LabelBorder';
import style from './style.module.css';

interface Props {
  setMatchFuel?: React.Dispatch<React.SetStateAction<boolean>>;
  setwfb?: React.Dispatch<React.SetStateAction<boolean>>;
  setKeepKMZEnabled?: React.Dispatch<React.SetStateAction<boolean>>;
  matchFuel: boolean;
  wfb: boolean;
  keepKMZEnabled: boolean;
  simulationDate: string;
  className?: string;
}

const JobConfiguration = ({
  setMatchFuel,
  setwfb,
  matchFuel,
  wfb,
  keepKMZEnabled,
  setKeepKMZEnabled,
  simulationDate,
  className,
}: Props) => {
  return (
    <LabelBorder label="Configuration" className={[style.configuration, className].join(' ')}>
      {!wfb && (
        <Tooltip title="Match Fuel layer in WFB">
          <FormControlLabel
            className={style.checkboxConfig}
            control={
              <Checkbox
                color="secondary"
                checked={matchFuel}
                disabled={!setMatchFuel || wfb}
                onChange={(event, checked) => setMatchFuel!(checked)}
              />
            }
            label="Match Fuel"
          />
        </Tooltip>
      )}
      <Tooltip title="Worst Fire Behaviour">
        <FormControlLabel
          className={style.checkboxConfig}
          control={
            <Checkbox
              color="secondary"
              checked={wfb}
              disabled={!setwfb}
              onChange={(event, checked) => setwfb!(checked)}
            />
          }
          label="Worst Fire Behaviour"
        />
      </Tooltip>
      <Tooltip title="Keep KMZ File">
        <FormControlLabel
          className={style.checkboxConfig}
          control={
            <Checkbox
              color="secondary"
              checked={keepKMZEnabled}
              disabled={!setKeepKMZEnabled}
              onChange={(event, checked) => setKeepKMZEnabled!(checked)}
            />
          }
          label="Keep KMZ File"
        />
      </Tooltip>
      {wfb && <div className={style.simulationDate}>{simulationDate} Simulation Date</div>}
    </LabelBorder>
  );
};

export default JobConfiguration;
